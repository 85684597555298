@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

    :root {
        --header-height: 97px;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Roboto', sans-serif;
        overflow-y: overlay;
    }

    /* Grid */
    .cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    /* Scrollbar CSS */
    html *::-webkit-scrollbar {
        border-radius: 0;
        width: 8px;
    }

    html *::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
    }

    html *::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(255, 255, 255, 0.4);
    }
}

/* MaterialUIPage */
.material-ui {
    margin-top: var(--header-height);
}
